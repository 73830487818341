import React from "react";

export default () => {
  return(
    <>
    <footer className="l-footer">
      <div className="l-footer--inner">
        <div className="sns-icon">
          <a href="https://www.instagram.com/working_forever/?ref=badge">
            <p className="sns-icon--img"><img src="/assets/img/icon_instagram.png" alt=""/></p>
            <p className="sns-icon--text">Instagram</p>
          </a>
        </div>
        <div className="slanting"></div> 
        <div className="contact">
        <a href="mailto:t-nishizawa@workingforever100years.com">Contact:t-nishizawa@workingforever100years.com</a>
        </div>
      </div>
      <div className="copyright">
        <p>Copyright © 2018 WORKING FOREVER.</p>
      </div>
    </footer>
    </>
  );
}