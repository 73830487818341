import React from "react";
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

// We can inject some CSS into the DOM.
const styles = theme => ({
  root: {
    fontWeight: 200,
    fontSize: "44px",
    marginBottom: "20px",
    [theme.breakpoints.down('xs')]: {
      fontSize: "26px",
      marginBottom: "8px",
    }
  },
});

export const h1 = (props) => {
  const { h1Text, classes,} = props;
  
  return (
    <>
      <Typography className={`${classNames(classes.root)}`}  component="h1"  variant="h1">{h1Text}</Typography>
    </>
    );
}

h1.propTypes = {
  classes: PropTypes.object.isRequired,
  h1Text: PropTypes.string,
};

export default withStyles(styles)(h1);