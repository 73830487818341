import React from "react";
import {Link} from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles =  theme => ({
  root: {
    // flexGrow: 1,
    display: 'block',
    // background:'#fff', 
  },
  h1: {
    fontSize: '28px',
    lineHeight: '1.2',
    marginBottom: '0',
    fontWeight:200,
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px',
      fontWeight: '400',
      // backgroundColor: theme.palette.main,
    },
  }
});

const Header = (props) => {
  const {classes} = props;
  return(
    <>
      <AppBar className={`l-header ${classes.root}`}>
        <div className="l-header--inner">
          <Typography component="h1" variant="h4" className={`l-header--logo ${classes.h1}`}>
            <Link to="/">Working Forever</Link>
          </Typography>
          <nav>
            <ul className="nav-global">
              <li className="nav-global--item"><Link to="/">Home</Link></li>
              <li className="nav-global--item"><Link to="/about">About</Link></li>
              <li className="nav-global--item"><Link to="/works">Works</Link></li>
              <li className="nav-global--item"><Link to="/instagram">Instagram</Link></li>
              <li className="nav-global--item"><Link to="/others">WORKING FOREVER<br/>実現委員会</Link></li>
            </ul>
          </nav>
        </div>
      </AppBar>
    </>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

// export default Header;
export default withStyles(styles)(Header);