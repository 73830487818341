import React from "react";
import H1 from "../Modules/H1";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// import "./index.css";

const text = {
    h1 : "Works"
  }

const styles = theme => ({
  grid: {
    alignItems:"center",
    [theme.breakpoints.down('xs')]: {
      margin: '0 0 20px 0',
      width: '100%',
    }
    },
  gridItem: {
    [theme.breakpoints.down('xs')]: {
      padding: '0 0 4px 0 !important', 
    }
  },
})


export default withStyles(styles)( (props) => {
  return(
    <>
      <H1 h1Text={text.h1} />
      <Grid container className={`${props.classes.grid}`} spacing={16}>
          <Grid item xs={12} sm={6} className={`${props.classes.gridItem}`}>
            <p className="box-description--title"><img src="https://placehold.jp/960x540.png" alt=""/></p>
          </Grid>
          <Grid item xs={12} sm={6} className={`${props.classes.gridItem}`}>
            <p className="box-description--text" >
            【メゾン・ランドゥメンヌ】<br/>
            パリ・フランス１６店舗、東京で２店舗展開するパリ発本格派ブーランジェリー・パティスリー
            </p>
          </Grid>
        </Grid>
        <Grid container direction="row-reverse" className={`${props.classes.grid}`} spacing={16}>
        <Grid item xs={12} sm={6} className={`${props.classes.gridItem}`}>
            <p className="box-description--title"><img src="https://placehold.jp/960x540.png" alt=""/></p>
          </Grid>
          <Grid item xs={12} sm={6} className={`${props.classes.gridItem}`}>
            <p className="box-description--text" >
            【パンと焼き菓子のPapapapa-n!!】<br/>
            本格的なクロワッサンを日常価格帯で提供する街のパン屋さん。
            </p>
          </Grid>
        </Grid>
        <Grid container className={`${props.classes.grid}`} spacing={16}>
        <Grid item xs={12} sm={6} className={`${props.classes.gridItem}`}>
            <p className="box-description--title"><img src="https://placehold.jp/960x540.png" alt=""/></p>
          </Grid>
          <Grid item xs={12} sm={6} className={`${props.classes.gridItem}`}>
            <p className="box-description--text" >
            【北鎌倉フレンチレストラン『シェ・ケンタロウ』】<br/>
            北鎌倉の古民家を改装した、大人のフレンチレストラン
            </p>
          </Grid>
        </Grid>
    </>
  )
})