import React from "react";
import H1 from "../Modules/H1";
import { withStyles } from '@material-ui/core/styles';

const text = {
    h1 : "Instagram"
  }

  const styles = theme => ({
    grid: {
      alignItems:"center",
      [theme.breakpoints.down('xs')]: {
        margin: '0 0 20px 0',
        width: '100%',
      }
      },
    gridItem: {
      [theme.breakpoints.down('xs')]: {
        padding: '0 0 4px 0 !important', 
      }
    },
  })
  

export default withStyles(styles)( (props) => {
  return (
    <>
        <H1 h1Text={text.h1} />
    </>
  )
});