import React from "react";

export default () =>(
      <>
      <div className="p-kv">
        <div className="p-kv--inner">
          <h2 className="p-kv--heading">Working<br />Forever<br/><span>Never Ending My Story</span></h2>
        </div>
      </div>
      </>
      )