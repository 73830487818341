import React from "react";
import H1 from "../Modules/H1";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// import "./index.css";

const text = {
    h1 : "WORKING FOREVER 実現委員会"
  }

const styles = theme => ({
  grid: {
    alignItems:"center",
    margin: "0 0 30px 0",
    padding: "0 0 30px 0",
    borderBottom: "1px solid #999",
    [theme.breakpoints.down('xs')]: {
      margin: '0 0 20px 0',
      width: '100%',
    }
    },
  gridItem: {
    [theme.breakpoints.down('xs')]: {
      padding: '0 0 4px 0 !important', 
    }
  },
  gridItemTitle: {
    margin: "0 0 15px 0",
    [theme.breakpoints.down('xs')]: {
      padding: '0 0 4px 0 !important', 
    }
  },
  gridItemText: {
    fontSize: "20px",
    [theme.breakpoints.down('xs')]: {
      // padding: '0 0 4px 0 !important', 
    }
  },

})


export default withStyles(styles)( (props) => {
  return(
    <>
      <H1 h1Text={text.h1} />
      <div className="p-others">
        <Grid container  className={`${props.classes.grid}`} spacing={16}>
        <Grid item xs={12} sm={4} className={`${props.classes.gridItem}`}>
              <p className={`${props.classes.gridItemText} u-center`} >
              # WORKING FOREVER<br/> 実現委員会
              </p>
            </Grid>
            <Grid item xs={12} sm={8} className={`${props.classes.gridItem}`}>
              <p className={`box-description--title ${props.classes.gridItemTitle}` }><img src="https://placehold.jp/960x540.png" alt=""/></p>
              <p className="c-doc-text">
                WORKING FOREVER実現委員会は、<br/>
                「おばあちゃんになってもずっとずっと、仕事してたい！」をコンセプトに、<br/>
                さまざまなジャンルで働く女性たちが、<br/>
                自分たちのネバーエンディングストーリーの実現に向けて、<br/>
                明るく楽しく面白く、その先の仕事人生を<br/>
                切り拓いていくための未来実現委員会です。<br/>
                <br/>
                WORKING FOREVERな人生を模索しながら、<br/>
                日々、さまざまな活動をしています！
              </p>
            </Grid>
          </Grid>
          <Grid container className={`${props.classes.grid}`} spacing={16}>
            <Grid item xs={12} sm={4} className={`${props.classes.gridItem}`}>
              <p className={`${props.classes.gridItemText} u-center`} >
              # 『築地広報会ギ』
              </p>
            </Grid>
            <Grid item xs={12} sm={8} className={`${props.classes.gridItem}`}>
              <p className={`box-description--title ${props.classes.gridItemTitle}`}><img src="/assets/img/img_other_01.jpg" alt=""/></p>
              <p className="c-doc-text">
              築地広報会ギは『熱くたのしくおもしろｋ！』をモットーに、<br/>
              広報・PR業界で活動する仲間とメディア関係者がこっそり集い、<br/>
              築地の町の温かさとPRの楽しさについて再確認する<br/>
              ゆるふわ会議です。<br/>
              毎回、築地の隠れた名店にて開催。<br/>
              築地を愛し、PRを愛する人々を応援＆お待ちしております。
              </p>
            </Grid>
          </Grid>
        </div>
    </>
  )
})