import React from "react";
import H1 from "../Modules/H1";
import Grid from '@material-ui/core/Grid';

const about = {
  h1 : "About",
}

const story = {
  h1 : "Story",
}

export const About = (props) => {
    return (
    <>
      <H1 h1Text={about.h1} />
      <div className={`box-description`}>
        <Grid container spacing={16}>
          <Grid item xs={12} sm={4}>
            <p className="box-description--title">Description</p>
          </Grid>
          <Grid item xs={12} sm={8}>  
            <p className="box-description--text" >
              WORKING FOREVER（ワーキング・フォーエバー）」は、<br />
              広報・PRの力で中小企業はじめ、<br />
              スタートアップ企業、ベンチャー企業を応援します。
            </p>
          </Grid>
        </Grid>
      </div>
      <div className="c-photo">
        <img src="/assets/img/mv_about.jpg" alt=""/>
        <div className="c-photo--text-box">
          <ul className="c-list">
            <li>広報・PRコンサルティング</li>
            <li>広報・PRプラン策定</li>
            <li>プレスカンファレンス企画・実施</li>
            <li>メディアリレーションズ活動　　　　etc…</li>
          </ul>
        </div>
      </div>
      <div className="c-photo">
        <img src="/assets/img/mv_story.jpg" alt=""/>
        <div className="c-photo--text-box">
        <h2 className="c-photo--heading">{story.h1}</h2>
          <h3 className="c-photo--title">
            「WORKING FOREVER（ワーキング・フォーエバー）」は、<br/>
            広報・PRの力で中小企業はじめ、<br/>
            スタートアップ企業、ベンチャー企業を応援します。
          </h3>
          <p className="c-photo--text">
            『広報部もないメーカーのPR担当』<br/>
            それが私の原点です。
          </p>
          <p className="c-photo--name">WORKING FOREVER<br />代表　西澤　朋子</p>
        </div>
      </div>
    </>
  )
}

export default About;
