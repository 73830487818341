import React from "react";
// import  "./css/reset.css";
// import PropTypes from 'prop-types';  
import "./css/index.css";
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import {BrowserRouter, Route} from "react-router-dom"
import Home from "./Home";
import About from "./About";
import Works from "./Works";
import Others from "./Others";
import Instagram from "./Instagram";
import Header from "./header";
import Footer from "./footer";

const styles = {
  root: {
    width: '100%',
    maxWidth: 500,
  },
};


const theme = createMuiTheme ({
    // ...theme,
    typography: {
      useNextVariants: true,
      fontFamily: [
        'Roboto',
        'Noto sans',
        'sans-serif',
      ].join(','),
    },
    palette: {
      primary: {
        light: '#757ce8',
        main: '#fefefe',
        dark: '#002884',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#ba000d',
        contrastText: '#000',
      },
    },
    // レスポンシブレイアウト用の指定
    breakpoints: {
      'keys': [
        'xs',
        'sm',
        'md',
        'lg',
        'xl',
      ],
      values: {
        'xs': 360, // スマホ用
        'sm': 768, // タブレット用
        'md': 992, // PC用
        'lg': 1000000000, 
        'xl': 1000000000,
      },
    },
    // overrides: {
    //   MuiTypography: {
    //     typography: {
    //       color: 'teal',
    //       marginTop: '16px',
    //       marginBottom: '16px',
    //     },
    //     title: {
    //       marginTop: '16px',
    //       marginBottom: '16px',
    //     },
    //   },
    // },
  });


const App = (props) => {
    // const { classes }= this.props
    return (
      <BrowserRouter>
        <MuiThemeProvider theme={theme}>
          <>
          <Header />
          <main className="l-wrap">
              <Route path="/" exact={true} component={Home}/>
            <div className="l-content">
              <Route path="/about"  component={About}/>
              <Route path="/works" component={Works}/>
              <Route path="/instagram" component={Instagram}/>
              <Route path="/others" component={Others}/>
            </div>
          </main>
          <Footer />
          </>
        </MuiThemeProvider>
      </BrowserRouter>
    );
};

// PropTypes.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

export default withStyles(styles)(App);